<template>
  <v-container fluid>
    <label class="label primary--text">
      <h3>Campos Calculados</h3>
    </label>
    <v-data-table
      class="elevation-1"
      :headers="columnHeaders"
      :items="mainColumns"
      :single-expand="true"
      :items-per-page="-1"
      item-key="sequencial"
      hide-default-footer
      fixed-header
      hide-default-header
      :height="mainColumnsLength > 10 ? 550 : null"
    >
      <template v-slot:no-data>
        <span>Não há dados</span>
      </template>
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr class="table-header">
            <th :style="`background-color: #d9d9d9 !important; text-align: center; width: ${header.width}px;`" v-for="header in props.headers" :key="header.text">
              <v-col v-if="header.text === 'Ações'" align="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="addColumn()"
                    >
                      <v-icon>
                        fas fa-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar Coluna</span>
                </v-tooltip>
              </v-col>
              <label class="label primary--text" v-if="header.text !== 'Ações'">
                {{ header.text }}
              </label>              
            </th>
          </tr>
        </thead>
      </template>     
      <template v-slot:[`item.name`]="{ item }">
        <v-row justify="center" class="my-0">
          <v-col cols="12" lg="9" align="center" class="py-0 my-0">
            <v-text-field
              v-model="item.name"
              placeholder="Informe"
              class="mt-6"
              color="textPrimary"
              dense
              solo
              :rules="[rule.required]"
              maxlength="30"
            />            
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.function`]="{ item }">
        <v-row justify="center" class="my-0">
          <v-col cols="12" lg="9" align="center" class="py-0 my-0">            
            <v-autocomplete
              v-model="item.function"
              :items="functions"
              item-text="text"
              item-value="value"
              placeholder="Informe"
              class="mt-6"
              color="textPrimary"
              dense
              solo
              :rules="[rule.required, hasValueBeenAdded(item, layout.virtualColumns)]"              
              validate-on-blur
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <td>
          <v-row class="pb-2">
            <v-col align="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    color="#fff"
                    block
                    icon
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    @click="deleteColumn(item)"
                  >
                    <v-icon color="red">
                      fas fa-trash-alt
                    </v-icon>
                  </v-btn>
                </template>
                <span>Excluir Coluna</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>    
    

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import { mapGetters } from 'vuex';


export default ({
  components: {
    SnackbarCustomize,
  },

  data: () => ({
    layout: {},
    formatter: null,
    transformationParameter: {},
    isVisualization: false,
    snackbar: {
      type: '',
      show: false,
      text: '',
    },
    functions: [
      {
        value: 'FAMILY_SEQUENCIAL',
        text: 'Sequencial Família',
      },
      {
        value: 'MEMBER_OF_FAMILY_SEQUENCIAL',
        text: 'Seq. Integrante Família',
      },
      {
        value: 'LINE_SEQUENCIAL',
        text: 'Sequencial Arquivo',
      },
    ],
    columnHeaders: [
      {
        text: 'Campo',
        value: 'sequencial',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Nome',
        value: 'name',
        align: 'center',
        sortable: false,
        width: 610,
      },
      {
        text: 'Função',
        value: 'function',
        align: 'center',
        sortable: false,
        width: 610,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: false,
      }
    ],
  }),

  props: {
    propsLayout: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    isCopy: {
      type: Boolean,
      required: true,
    }
  },

  watch: {
    propsLayout: {
      handler(val) {
        if (val) {
          this.layout = val;
        }
      },
    },
  },

  mounted() {
    if (sessionStorage.getItem('visualization')) {
      this.isVisualization = true;
    }
  },

  computed: {
    ...mapGetters({
      layoutData: 'layout-import/layout'
    }), 
    columnHeadersComputed() {
      return this.columnHeaders;
    },
    mainColumns() {
      if (this.layout && this.layout.virtualColumns && this.layout.virtualColumns.length > 0) {
        return this.layout.virtualColumns;
      } else {
        return this.layout.virtualColumns = [];
      }
    },
    mainColumnsLength() {
      if (this.layout && this.layout.virtualColumns && this.layout.virtualColumns.length > 0) {
        return this.layout.virtualColumns.reduce((count) => (count + 1), 0);
      }
      return 0;
    },
  },

  methods: {    
    async addColumn() {
      const orderColumn = this.layout.columns.length + this.mainColumnsLength + 1;

      this.layout.virtualColumns.push({
        sequencial: orderColumn,
        name: '',
        function: '',
      });

      this.reorderingLayout();
    },
    deleteColumn(item) {
      const index = this.layout.virtualColumns.findIndex((element) => element.sequencial === item.sequencial);
      this.layout.virtualColumns.splice(index, 1);

      this.reorderingLayout();
    },
    reorderingLayout() {
      this.layout.virtualColumns.sort((a, b) => (a.sequencial > b.sequencial ? 1 : -1));
      const columnsOrdened = [];
      this.layout.virtualColumns = this.layout.virtualColumns.forEach((element, index) => {
        columnsOrdened.push({
          ...element,
          sequencial: this.layout.columns.length + index + 1,
        });
      });
      this.layout.virtualColumns = columnsOrdened;
      this.$emit('updateLayout', this.layout)
    },
    hasValueBeenAdded(value, array) {
      const repeatedItems = array.filter(item => item.function === value.function);

      if (repeatedItems.length > 1) {
          const lastItem = repeatedItems.reduce((last, current) => {
              return current.sequencial > last.sequencial ? current : last;
          });

          if (lastItem.sequencial === value.sequencial) {
            return this.getTextFromFunctions(value.function) + ' já adicionado, utilize outro';
          }
      }

      return true;
    },
    getTextFromFunctions(value) {
      const item = this.functions.find(func => func.value === value);
      return item ? item.text : null;
    },  
    
  },

  created() {
    this.rule = new Rules();
  },
});
</script>

<style scoped>
.isSelectedTransformation {
  background-color: rgba(234, 73, 101, 0.15);
  color: #ea4965;
}
</style>
